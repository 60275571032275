import { cssUtils } from '@diagrid/cloud-ui-shared/utils/theme';
import { GlobalStyles } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

const legendMaxWidth = 300;

export function ChartStyle() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '&.apexcharts-canvas': {
          // Tooltip
          '.apexcharts-xaxistooltip': {
            ...cssUtils(theme).bgColor(),
            border: 0,
            color: theme.palette.text.primary,
            boxShadow: theme.customShadows.dropdown,
            borderRadius: Number(theme.shape.borderRadius) * 1.5,
            '&:before': { borderBottomColor: 'transparent' },
            '&:after': { borderBottomColor: alpha(theme.palette.background.default, 0.8) },
          },
          '.apexcharts-tooltip.apexcharts-theme-light': {
            ...cssUtils(theme).bgColor(),
            border: 0,
            boxShadow: theme.customShadows.dropdown,
            borderRadius: Number(theme.shape.borderRadius) * 1.5,
            '& .apexcharts-tooltip-title': {
              border: 0,
              textAlign: 'center',
              fontWeight: theme.typography.fontWeightBold,
              backgroundColor: theme.palette.grey['500_16'],
              color: theme.palette.text[theme.palette.mode === 'light' ? 'secondary' : 'primary'],
            },
          },
          // Legend
          '.apexcharts-legend': {
            padding: 0,
            maxWidth: legendMaxWidth,
          },
          '.apexcharts-legend-series': {
            textWrapStyle: 'balance',
            wordBreak: 'break-all',
            display: 'grid !important',
            gridTemplateColumns: '20px 1fr',
            alignItems: 'center',
            padding: '8px 0 0 8px',
          },
          '.apexcharts-legend-marker': {
            marginRight: 8,
          },
          '.apexcharts-legend-text': {
            lineHeight: '18px',
          },
        },
        '.apexcharts-custom-legend-container': {
          display: 'grid',
          gridTemplateColumns: '1fr 3fr',
          '.apexcharts-legend': {
            padding: '16px 0 0 8px',
            maxWidth: legendMaxWidth,
            display: 'block',
          },
          '.apexcharts-legend-series': {
            textWrapStyle: 'balance',
            wordBreak: 'break-word',
            display: 'grid !important',
            gridTemplateColumns: '20px 1fr',
            padding: '8px 0 0 8px',
            gridGap: 8,
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          },
          '.apexcharts-legend-marker': {
            marginRight: 8,
            display: 'flex',
            alignItems: 'end',
            paddingBottom: 2,
          },
          '.apexcharts-legend-text': {
            lineHeight: '18px',
            // this are built in styles but for some reason on refresh of the data, marginLeft is overwritten and causes the text to jump
            paddingLeft: '15px !important',
            marginLeft: '-15px !important',
            width: '100%',
            maxWidth: legendMaxWidth,
            textWrapStyle: 'pretty',
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
          },
        },
      }}
    />
  );
}
